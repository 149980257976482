<template>
  <div>
    <b-row class="profile-icons-svg">
      <b-col lg="4">
        <div class="iq-card ">
        <div class="iq-card-body pb-1">
          <div class="d-flex align-items-center justify-content-between">
              <p class="font-size-26 p-0 m-0">{{ $t('userProfile.insurance') }}</p>
          <svg xmlns="http://www.w3.org/2000/svg" width="32.483" height="32.483" viewBox="0 0 37.483 37.483">
            <path id="_17e264738a73720c3ce88a761514fcfb" data-name="17e264738a73720c3ce88a761514fcfb" d="M16.78,1.781a3.282,3.282,0,0,1,5.079,0l1.627,1.988,2.4-.908a3.282,3.282,0,0,1,4.4,2.539L30.7,7.936l2.535.416a3.282,3.282,0,0,1,2.539,4.4l-.908,2.4,1.988,1.628a3.282,3.282,0,0,1,0,5.079L34.87,23.487l.908,2.4a3.282,3.282,0,0,1-2.539,4.4L30.7,30.7l-.415,2.535a3.282,3.282,0,0,1-4.4,2.539l-2.4-.908-1.627,1.988a3.282,3.282,0,0,1-5.079,0l-1.628-1.988-2.4.908a3.282,3.282,0,0,1-4.4-2.539L7.936,30.7,5.4,30.288a3.282,3.282,0,0,1-2.539-4.4l.908-2.4L1.781,21.859a3.282,3.282,0,0,1,0-5.079l1.988-1.628-.908-2.4A3.282,3.282,0,0,1,5.4,8.352l2.535-.416L8.351,5.4a3.282,3.282,0,0,1,4.4-2.539l2.4.908Zm1.239,24.442L29.505,14.737l-2.321-2.321L16.859,22.742l-5.4-5.4L9.135,19.66,15.7,26.223a1.641,1.641,0,0,0,2.321,0Z" transform="translate(-0.578 -0.579)" fill="#646464" fill-rule="evenodd"/>
          </svg>
          </div>
          <p class="font-size-30 text-end m-0 pt-4">{{ userStatistics.insurance }}</p>
        </div>
          </div>
      </b-col>
      <b-col lg="4">
        <div class="iq-card ">
          <div class="iq-card-body pb-1">
            <div class="d-flex align-items-center justify-content-between">
              <p class="font-size-26 p-0 m-0">{{ $t('userProfile.transportation') }}</p>
              <svg id="delivery-truck_1_" data-name="delivery-truck (1)" xmlns="http://www.w3.org/2000/svg" width="30.466" height="20.496" viewBox="0 0 33.466 24.496">
            <g id="Group_145" data-name="Group 145" transform="translate(0 0)">
              <g id="Group_144" data-name="Group 144">
                <path id="Path_133" data-name="Path 133" d="M0,234.777a.611.611,0,0,0,.584.665h.932a4.131,4.131,0,0,1,3.706-2.633,4.131,4.131,0,0,1,3.706,2.633h12.11v-5.377H0Z" transform="translate(0 -215.695)" />
                <path id="Path_134" data-name="Path 134" d="M33.218,277.32a2.991,2.991,0,1,0,2.746,2.981A2.871,2.871,0,0,0,33.218,277.32Z" transform="translate(-27.997 -258.785)" />
                <path id="Path_135" data-name="Path 135" d="M285.771,192.312l-2.616-1.248H275v8.816h1.583a3.925,3.925,0,0,1,7.412,0h1.549a.612.612,0,0,0,.584-.665v-6.262A.7.7,0,0,0,285.771,192.312Z" transform="translate(-252.663 -180.133)" />
                <path id="Path_136" data-name="Path 136" d="M280.466,122.15a1.85,1.85,0,0,0-1.684-1.088H275v4.76h7.222Z" transform="translate(-252.663 -116.301)" />
                <path id="Path_137" data-name="Path 137" d="M19.172,67.064H1.856A1.962,1.962,0,0,0,0,69.131V80.023H21.038V69.131s0-.006,0-.01A1.969,1.969,0,0,0,19.172,67.064Z" transform="translate(0 -67.064)" />
                <path id="Path_138" data-name="Path 138" d="M309.051,277.32A2.991,2.991,0,1,0,311.8,280.3h0A2.871,2.871,0,0,0,309.051,277.32Z" transform="translate(-281.425 -258.785)" />
              </g>
            </g>
          </svg>
            </div>
            <p class="font-size-30 text-end m-0 pt-4">{{ userStatistics.transportation }}</p>
          </div>
        </div>
      </b-col>
      <b-col lg="4">
        <div class="iq-card ">
          <div class="iq-card-body pb-1">
            <div class="d-flex align-items-center justify-content-between">
              <p class="font-size-26 p-0 m-0">{{ $t('userProfile.reservations') }}</p>
              <svg xmlns="http://www.w3.org/2000/svg" width="25.135" height="25.135" viewBox="0 0 30.135 30.135">
                <path id="doctor-bag" d="M29.786,9.7H26.438V8.022A5.022,5.022,0,0,0,21.416,3h-6.7A5.022,5.022,0,0,0,9.7,8.022V9.7H6.348A3.358,3.358,0,0,0,3,13.045V29.787a3.358,3.358,0,0,0,3.348,3.348H29.786a3.358,3.358,0,0,0,3.348-3.348V13.045A3.358,3.358,0,0,0,29.786,9.7ZM13.045,8.022a1.674,1.674,0,0,1,1.674-1.674h6.7A1.674,1.674,0,0,1,23.09,8.022V9.7H13.045ZM23.09,23.09H19.741v3.348H16.393V23.09H13.045V19.742h3.348V16.393h3.348v3.348H23.09Z" transform="translate(-3 -3)" />
              </svg>
            </div>
            <p class="font-size-30 text-end m-0 pt-4">{{ userStatistics.reservations }}</p>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row class="profile-icons-svg">
      <b-col lg="4">
        <div class="iq-card ">
          <div class="iq-card-body pb-1">
            <div class="d-flex align-items-center justify-content-between">
              <p class="font-size-26 p-0 m-0">{{ $t('userProfile.orders') }}</p>
              <svg id="_58ec6627826e87c5e999998c7fdd9d50" data-name="58ec6627826e87c5e999998c7fdd9d50" xmlns="http://www.w3.org/2000/svg" width="29.817" height="30.79" viewBox="0 0 34.817 35.79">
                <ellipse id="Ellipse_147" data-name="Ellipse 147" cx="3.441" cy="3.441" rx="3.441" ry="3.441" transform="translate(24.652 28.909)" fill="#646464"/>
                <ellipse id="Ellipse_148" data-name="Ellipse 148" cx="3.441" cy="3.441" rx="3.441" ry="3.441" transform="translate(9.645 28.909)" fill="#646464"/>
                <path id="Path_1424" data-name="Path 1424" d="M38.281,29.152a1.476,1.476,0,0,1-1.558,1.376H14.255a4.6,4.6,0,0,1-4.55-3.18l-.576-2.325L4.7,7.351l-.67-2.67A1.389,1.389,0,0,1,5.218,3.029a1.579,1.579,0,0,1,1.87,1.046l.763,3.055,4.472,17.892.421,1.707a1.543,1.543,0,0,0,1.511,1.046H36.723A1.476,1.476,0,0,1,38.281,29.152Z" transform="translate(-3.995 -2.995)" fill="#646464"/>
                <path id="Path_1425" data-name="Path 1425" d="M38.476,11.216,35.5,20.85a4.652,4.652,0,0,1-4.52,3.042H8.877L4.45,6.22A1.782,1.782,0,0,1,5.307,6H33.956a4.917,4.917,0,0,1,3.723,1.62A3.752,3.752,0,0,1,38.476,11.216Z" transform="translate(-3.824 -1.865)" fill="#646464"/>
              </svg>
            </div>
            <p class="font-size-30 text-end m-0 pt-4">{{ userStatistics.orders }}</p>
          </div>
        </div>
      </b-col>
      <b-col lg="4">
        <div class="iq-card ">
          <div class="iq-card-body pb-1">
            <div class="d-flex align-items-center justify-content-between">
              <p class="font-size-26 p-0 m-0">{{ $t('userProfile.favourite') }}</p>
              <svg xmlns="http://www.w3.org/2000/svg" width="33.151" height="26.662" viewBox="0 0 38.151 31.662">
                <path id="c74aa330893241edd41bc0bfbdb19920" d="M2.2,14.62a13.44,13.44,0,0,0,3.933,10.03C9.28,27.8,19.7,34.875,20.1,35.269a2.8,2.8,0,0,0,1.18.393,2.8,2.8,0,0,0,1.18-.393c.393-.393,10.816-7.276,13.963-10.62a13.44,13.44,0,0,0,3.933-10.03A10.575,10.575,0,0,0,29.732,4a10.468,10.468,0,0,0-8.26,4.523A10.592,10.592,0,0,0,2.2,14.62Z" transform="translate(-2.2 -4)" fill="#646464"/>
              </svg>
            </div>
            <p class="font-size-30 text-end m-0 pt-4">{{ userStatistics.favourites }}</p>
          </div>
        </div>
      </b-col>
      <b-col lg="4">
        <div class="iq-card ">
          <div class="iq-card-body pb-1">
            <div class="d-flex align-items-center justify-content-between">
              <p class="font-size-26 p-0 m-0">{{ $t('userProfile.wallet_balance') }}</p>
              <svg xmlns="http://www.w3.org/2000/svg" width="26.483" height="25.966" viewBox="0 0 31.483 30.966">
                <path id="c275da62a737e9e440232d6c41c485f7" d="M29.548,9.616H4.952V8.632L26.6,6.9V8.632h2.952V5.681A3.324,3.324,0,0,0,25.654,2.3L5.9,5.124A4.688,4.688,0,0,0,2,9.616V29.293a3.935,3.935,0,0,0,3.935,3.935H29.548a3.935,3.935,0,0,0,3.935-3.935V13.552A3.935,3.935,0,0,0,29.548,9.616ZM26.6,23.4a2.953,2.953,0,1,1,2.954-2.952A2.953,2.953,0,0,1,26.6,23.4Z" transform="translate(-2 -2.263)" />
              </svg>
            </div>
            <p class="font-size-30 text-end m-0 pt-4">{{ userStatistics.wallet_balance }}</p>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="6" v-for="(item,index) in charts" :key="index">
        <iq-card>
          <template v-slot:headerTitle>
            <h4>{{ item.title }}</h4>
          </template>
          <template v-slot:body>
            <ApexChart :element="item.type" :chartOption="item.bodyData"/>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { core } from '@/config/pluginInit'
import ApexChart from '../components/hightCharts'
export default {
  name: 'user-home',
  components: { ApexChart },
  data () {
    return {
      charts: [
        {
          title: 'Line Chart',
          type: 'line',
          bodyData: {
            chart: {
              height: 350,
              type: 'line',
              zoom: {
                enabled: false
              }
            },
            series: [{
              name: 'Desktops',
              data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
            }],
            dataLabels: {
              enabled: false
            },
            stroke: {
              curve: 'straight'
            },
            title: {
              text: 'Product Trends by Month',
              align: 'left'
            },
            grid: {
              row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
              }
            },
            xaxis: {
              categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep']
            }
          }
        },
        {
          title: 'Column Chart',
          type: 'column',
          bodyData: {
            chart: {
              height: 350,
              type: 'bar'
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: '55%',
                borderRadius: 4
              }
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              show: true,
              width: 2,
              colors: ['transparent']
            },
            colors: ['#0084ff', '#00ca00', '#ffc107'],
            series: [{
              name: 'Net Profit',
              data: [44, 55, 57, 56, 61, 58]
            }, {
              name: 'Revenue',
              data: [76, 85, 101, 98, 87, 105]
            }, {
              name: 'Free Cash Flow',
              data: [35, 41, 36, 26, 45, 48]
            }],
            xaxis: {
              categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul']
            },
            yaxis: {
              title: {
                text: '$ (thousands)'
              }
            },
            fill: {
              opacity: 1

            },
            tooltip: {
              y: {
                formatter: function (val) {
                  return '$ ' + val + ' thousands'
                }
              }
            }
          }
        },
        {
          title: 'Mixes Chart',
          type: 'mixes',
          bodyData: {
            chart: {
              height: 350,
              type: 'line',
              stacked: false
            },
            stroke: {
              width: [0, 2, 5],
              curve: 'smooth'
            },
            plotOptions: {
              bar: {
                columnWidth: '50%'
              }
            },
            colors: ['#ffc107', '#00ca00', '#0084ff'],
            series: [{
              name: 'Facebook',
              type: 'column',
              data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30]
            }, {
              name: 'Vine',
              type: 'area',
              data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43]
            }, {
              name: 'Dribbble',
              type: 'line',
              data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39]
            }],
            fill: {
              opacity: [0.85, 0.25, 1],
              gradient: {
                inverseColors: false,
                shade: 'light',
                type: 'vertical',
                opacityFrom: 0.85,
                opacityTo: 0.55,
                stops: [0, 100, 100, 100]
              }
            },
            labels: ['01/01/2003', '02/01/2003', '03/01/2003', '04/01/2003', '05/01/2003', '06/01/2003', '07/01/2003', '08/01/2003', '09/01/2003', '10/01/2003', '11/01/2003'],
            markers: {
              size: 0
            },
            xaxis: {
              type: 'datetime'
            },
            yaxis: {
              min: 0
            },
            tooltip: {
              shared: true,
              intersect: false,
              y: {
                formatter: function (y) {
                  if (typeof y !== 'undefined') {
                    return y.toFixed(0) + ' views'
                  }
                  return y
                }
              }
            },
            legend: {
              labels: {
                useSeriesColors: true
              },
              markers: {
                customHTML: [
                  function () {
                    return ''
                  }, function () {
                    return ''
                  }, function () {
                    return ''
                  }
                ]
              }
            }
          }
        },
        {
          title: 'Bubble Charts',
          type: 'bubble',
          bodyData: {
            chart: {
              height: 350,
              type: 'bubble'
            },
            dataLabels: {
              enabled: false
            },
            series: [{
              name: 'Product1',
              data: this.generateData(new Date('11 Feb 2017 GMT').getTime(), 20, {
                min: 10,
                max: 40
              })
            },
            {
              name: 'Product2',
              data: this.generateData(new Date('11 Feb 2017 GMT').getTime(), 20, {
                min: 10,
                max: 40
              })
            },
            {
              name: 'Product3',
              data: this.generateData(new Date('11 Feb 2017 GMT').getTime(), 20, {
                min: 10,
                max: 40
              })
            }
            ],
            fill: {
              type: 'gradient'
            },
            colors: ['#0084ff', '#00ca00', '#e64141'],
            title: {
              text: '3D Bubble Chart'
            },
            xaxis: {
              tickAmount: 12,
              type: 'datetime',

              labels: {
                rotate: 0
              }
            },
            yaxis: {
              max: 40
            },
            theme: {
              palette: 'palette2'
            }
          }
        },
        {
          title: 'Pie Charts',
          type: 'pie',
          bodyData: {
            chart: {
              width: 380,
              type: 'pie'
            },
            labels: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E'],
            series: [44, 55, 13, 43, 22],
            colors: ['#0084ff', '#00ca00', '#e64141', '#ffd400', '#00d0ff'],
            responsive: [{
              breakpoint: 480,
              options: {
                chart: {
                  width: 200
                },
                legend: {
                  position: 'bottom'
                }
              }
            }]
          }
        },
        {
          title: 'Line Area Chart',
          type: 'line-area',
          bodyData: {
            chart: {
              height: 350,
              type: 'area'
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              curve: 'smooth'
            },
            colors: ['#0084ff', '#00ca00'],
            series: [{
              name: 'series1',
              data: [31, 40, 28, 51, 42, 109, 100]
            }, {
              name: 'series2',
              data: [11, 32, 45, 32, 34, 52, 41]
            }],

            xaxis: {
              type: 'datetime',
              categories: ['2018-09-19T00:00:00', '2018-09-19T01:30:00', '2018-09-19T02:30:00', '2018-09-19T03:30:00', '2018-09-19T04:30:00', '2018-09-19T05:30:00', '2018-09-19T06:30:00']
            },
            tooltip: {
              x: {
                format: 'dd/MM/yy HH:mm'
              }
            }
          }
        },
        {
          title: 'Bar Chart',
          type: 'bar',
          bodyData: {
            chart: {
              height: 350,
              type: 'bar'
            },
            plotOptions: {
              bar: {
                horizontal: true
              }
            },
            dataLabels: {
              enabled: false
            },
            series: [{
              data: [470, 540, 580, 690, 1100, 1200, 1380]
            }],
            xaxis: {
              categories: ['Netherlands', 'Italy', 'France', 'Japan', 'United States', 'China', 'Germany']
            }
          }
        },
        {
          title: 'Radial Bar Charts',
          type: 'radial',
          bodyData: {
            chart: {
              height: 350,
              type: 'radialBar'
            },
            plotOptions: {
              radialBar: {
                dataLabels: {
                  name: {
                    fontSize: '22px'
                  },
                  value: {
                    fontSize: '16px'
                  },
                  total: {
                    show: true,
                    label: 'Total',
                    formatter: function (w) {
                      // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                      return 249
                    }
                  }
                }
              }
            },
            series: [44, 55, 67, 83],
            labels: ['Apples', 'Oranges', 'Bananas', 'Berries'],
            colors: ['#0084ff', '#00ca00', '#e64141', '#ffd400']
          }
        }
      ],
      userStatistics: {
        insurance: 36,
        transportation: 36,
        reservations: 36,
        orders: 36,
        favourites: 36,
        wallet_balance: 36
      }
    }
  },
  methods: {
    generateData (baseval, count, yrange) {
      var i = 0
      var series = []
      while (i < count) {
        var y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min
        var z = Math.floor(Math.random() * (75 - 15 + 1)) + 15

        series.push([baseval, y, z])
        baseval += 86400000
        i++
      }
      return series
    },
    getStatistics () {
      const userId = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).user.id : ''
      if (!userId) this.$router.push({ name: 'login' })
    }
  },
  mounted () {
    core.index()
  }
}
</script>

<style>
.text-end{
  text-align: end;
}
.profile-icons-svg svg path {
  fill: var(--iq-secondary-dark) !important;
}
</style>
